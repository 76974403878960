$bg-color: #1e1e1e;
$search-bg: #252526;
$primary: #eee;

body {
  margin: 0;
  background-color: $bg-color;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
}

.paper {
  background-color: #333333;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .clock {
    font-size: 50px;
    color: $primary;
  }

  .search {
    margin-top: 50px;
    width: 650px;

    form {
      @extend .paper;
      box-sizing: border-box;
      border-radius: 4px;
      display: block;
      position: relative;
      height: 2.8em;
      padding-left: 0.75em;
      padding-right: 3.5em;

      .search-input {
        background-color: #444444;
        color: #cccccc;
        font-size: 1.1em;
        font-weight: normal;
        display: block;
        width: 100%;
        background: none;
        outline: none;
        border: none;
        padding: 0;
        height: 2.545455em;
        z-index: 1;
        position: relative;
      }

      .search-button {
        box-sizing: content-box;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        text-decoration: none !important;
        width: 1em;
        display: block;
        cursor: pointer;
        background: transparent;
        background-size: auto 20px;
        text-align: center;
        border: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 2px;
        left: auto;
        margin: auto;
        z-index: 2;
        outline: none;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }

      .search-button {
        border-radius: 0 4px 4px 0;
        min-width: 26px;
        font-size: 1.25em;
        padding: 0 0.64em;
        height: auto;
        min-height: 1.8em;
        margin-top: -1px;
        margin-bottom: -1px;
        margin-right: -3px;
        line-height: 1.5;
        background-image: url("../icon/search-light.svg");
      }
    }
  }

  .widgets {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    max-width: 1120px;

    .widget {
      @extend .paper;
      padding: 10px;
      margin: 30px 15px 0;
      width: 230px;
      min-height: 150px;

      h2 {
        margin: 0 0 10px;
        color: #eeeeee;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          margin-top: 3px;

          a {
            color: #cccccc;
            text-decoration: none;
            display: block;
          }
        }
      }
    }
  }
}
